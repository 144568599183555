<template>
  <div>
    <div class="tabs">
      <div class="side_nav_wrapper">
        <div class="__side_nav">
          <button
            class="btn"
            v-if="!disallowedUsers"
            :class="[
              $route.path.includes == '/admin/promo_codes'
                ? '__side_item_active'
                : '',
            ]"
            @click="$router.replace('/admin/promo_codes').catch((err) => {})"
          >
            Promos
          </button>
          <button
            class="btn"
            :class="[
              $route.path == '/admin/referrals' ? '__side_item_active' : '',
            ]"
            @click="$router.replace('/admin/referrals').catch((err) => {})"
          >
            Referrals
          </button>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Referrals" name="referrals" :lazy="true">
        <Referral></Referral>
      </el-tab-pane>
      <el-tab-pane
        label="Referral Settings"
        name="referral-settings"
        :lazy="true"
        v-if="!disallowedUsers"
      >
        <ReferralSettings></ReferralSettings>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import ReferralSettings from "./ReferralSettings.vue";
  import Referral from "./Referral.vue";
  export default {
    components: {
      ReferralSettings,
      Referral,
    },
    data() {
      return {
        propKey: 0,
        activeName: "referrals",
      };
    },
    computed: {
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
  };
</script>
<style scoped>
  .tabs {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #e5e5e5;
  }
</style>
