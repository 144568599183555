var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"side_nav_wrapper"},[_c('div',{staticClass:"__side_nav"},[(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
            _vm.$route.path.includes == '/admin/promo_codes'
              ? '__side_item_active'
              : '',
          ],on:{"click":function($event){_vm.$router.replace('/admin/promo_codes').catch((err) => {})}}},[_vm._v(" Promos ")]):_vm._e(),_c('button',{staticClass:"btn",class:[
            _vm.$route.path == '/admin/referrals' ? '__side_item_active' : '',
          ],on:{"click":function($event){_vm.$router.replace('/admin/referrals').catch((err) => {})}}},[_vm._v(" Referrals ")])])])]),_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"Referrals","name":"referrals","lazy":true}},[_c('Referral')],1),(!_vm.disallowedUsers)?_c('el-tab-pane',{attrs:{"label":"Referral Settings","name":"referral-settings","lazy":true}},[_c('ReferralSettings')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }